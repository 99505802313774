import * as moment from "moment";
import axios from "axios";
import { calendarSchedule } from "@/helpers/links";
import store from "../store";

import { SUBSCRIPTIONS } from "@/helpers/backendConstants";
import { subscriptionPlans } from "@/helpers/selfCheckout";

moment.locale(store.getters.currentLanguageCode);

export const today = moment().format("YYYY-MM-DD");

export const selfCheckoutToday = moment().utc().locale("pt-br");

export const todayUtc = moment().utc().locale("pt-br");

export const emails = {
  experience: "experience@conectasuite.com",
  suporte: "suporte@conectasuite.com",
  ti: "ti@conectanuvem.com.br",
  financial: "financeiro@conectasuite.com",
};

export const telephone = "+55 (48) 4062-1323";

export const weekDayNumbersBD = {
  1: "Domingo",
  2: "Segunda",
  3: "Terça",
  4: "Quarta",
  5: "Quinta",
  6: "Sexta",
  7: "Sábado",
};

export const eventColors = [
  "success",
  "#1B5E20",
  "#40C4FF",
  "#3F51B5",
  "#9575CD",
  "#673AB7",
  "#FB8C00",
  "#E65100",
  "#6D4C41",
  "#4E342E",
  "#78909C",
  "#37474F",
  "#00897B",
  "#00695C",
  "#AFB42B",
  "#827717",
  "#00838F",
  "#6200EA",
];

export function greaterOrEqualToday(date) {
  if (!date) return false;

  const today = moment().locale("pt-br").format("YYYY-MM-DD");
  const dateToCheck = moment(date).format("YYYY-MM-DD");

  return dateToCheck >= today;
}

export function addSomeDays(date, days) {
  let dateToAdd = moment(date);
  return dateToAdd.add(days, "d");
}

export function ticketExpiration(next_billing, subscription_type) {
  const nextBilling = moment(next_billing, "DD/MM/YYYY");

  if (subscription_type === SUBSCRIPTIONS.ANNUAL) {
    return formatDate(
      nextBilling.add(subscriptionPlans.ANNUAL.ticketExpiration, "d")
    );
  } else {
    return formatDate(
      nextBilling
        .add(1, "M")
        .set("date", subscriptionPlans.FLEX.ticketExpiration)
    );
  }
}

export function getRemainingTrialDays(end_trial) {
  moment().locale("pt-br");

  const endTrialDate = moment(end_trial);

  const diffDate = endTrialDate.diff(moment().startOf("day"), "days");

  if (endTrialDate.isAfter(moment(), "day")) {
    return `${
      diffDate === 1
        ? diffDate + " dia de teste restante"
        : diffDate + " dias de teste restantes"
    }`;
  } else if (endTrialDate.isSame(moment(), "day")) {
    return "Último dia de teste!";
  } else if (endTrialDate.isBefore(today)) {
    return `${
      diffDate === -1
        ? "Expirado há " + diffDate + " dia"
        : "Expirado há " + diffDate + " dias"
    } `;
  } else {
    return false;
  }
}

export function maskCNPJ(cnpj) {
  if (cnpj) {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
  return "";
}

export function maskCEP(cep) {
  if (cep) {
    return cep.replace(/^(\d{5})(\d{3})/, "$1-$2");
  } else {
    return "";
  }
}

export function validateCnpj(cnpj = "") {
  if (!cnpj) {
    return false;
  }

  const cleanedCnpj = cnpj.replace(/\D/g, "");

  if (cleanedCnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(cleanedCnpj)) {
    return false;
  }

  let size = cleanedCnpj.length - 2;
  let numbers = cleanedCnpj.substring(0, size);
  const digits = cleanedCnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result.toString() !== digits.charAt(0)) {
    return false;
  }

  size = size + 1;
  numbers = cleanedCnpj.substring(0, size);
  sum = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result.toString() !== digits.charAt(1)) {
    return false;
  }

  return true;
}

export function preValidateCNPJ(cnpj = "") {
  const cnpjLengthWithMask = 18;
  const cnpjLengthWithoutMask = 14;
  const invalidCnpjs = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999",
  ];
  if (cnpj.length !== cnpjLengthWithMask) return false;

  cnpj = removeCompanyInfoMask("cnpj", cnpj);

  if (cnpj.length !== cnpjLengthWithoutMask) return false;

  // Elimina CNPJs inválidos conhecidos
  if (invalidCnpjs.includes(cnpj)) {
    return false;
  }
  return true;
}

export function checkIfDatesAreEqual(date1, date2) {
  const firstDate = moment(date1).format("YYYY-MM-DD");
  const secondDate = moment(date2).format("YYYY-MM-DD");
  return moment(firstDate).isSame(moment(secondDate));
}

export function hasDecimal(num) {
  const numStr = num.toString();
  if (numStr.includes(".")) {
    return parseInt(numStr.split(".")[1]) > 0;
  }
  return false;
}

export function getCurrentWeekNumber(date = false) {
  return date ? moment(date).isoWeekday() : moment().isoWeekday();
}

export function getCurrentWeekDay(date) {
  return moment(date).format("dddd");
}

// Exemplo de retorno: "Quarta-feira". Feito para uso com as numerações de weekDayNumbersBD
export function getWeeyDayNameByNumber(day_number) {
  return moment()
    .day(day_number - 1)
    .format("dddd");
}

export function getWeekDayByNumber(day_number) {
  return moment().day(day_number - 1);
}

export function getCurrentWeek() {
  var currentDate = moment();
  var weekStart = currentDate.clone().weekday(0);
  var days = [];
  let countDaysInBD = 1;
  for (var i = 0; i <= 6; i++) {
    days.push({
      value: countDaysInBD,
      name: moment(weekStart).add(i, "days").format("dddd"),
      key: "weekday",
    });
    countDaysInBD++;
  }
  return days;
}

export function getWeekDays() {
  var currentDate = moment();
  var weekStart = currentDate.clone().weekday(0);
  var days = [];
  let countDaysInBD = 1;
  for (var i = 0; i <= 6; i++) {
    days.push({
      day_number: countDaysInBD,
      day_name: moment(weekStart).add(i, "days").format("ddd"),
    });
    countDaysInBD++;
  }
  return days;
}

export function getCurrentMonth(month = null) {
  var monthStart = moment().startOf("month");
  var monthSize = moment().daysInMonth();
  const year = moment().format("YYYY");

  if (month) {
    monthStart = moment(year + "-" + month, "YYYY-M").startOf("month");
    monthSize = moment(year + "-" + month, "YYYY-M").daysInMonth();
  }

  var days = [];

  for (var i = 0; i <= monthSize - 1; i++) {
    const date = moment(monthStart).add(i, "days").format("YYYY-MM-DD");
    days.push({
      date: date,
      day_number: moment(monthStart).add(i, "days").day() + 1,
      day_name: moment(monthStart).add(i, "days").format("ddd"),
    });
  }
  return days;
}

export function getPreviousAndNextMonth() {
  const currentMonth = moment().format("M");
  const previousMonth = moment().subtract(1, "months").format("M");
  const nextMonth = moment().add(1, "month").format("M");

  const months = [previousMonth, currentMonth, nextMonth];

  let year = [];
  months.forEach((month) => {
    Array.prototype.push.apply(year, getCurrentMonth(month));
  });
  return year;
}

export function getCurrentYear() {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  let year = [];
  months.forEach((month) => {
    Array.prototype.push.apply(year, getCurrentMonth(month));
  });
  return year;
}

export const firstDayOfYear = moment().startOf("year");

export function addSpecificHour(date, add) {
  return moment(date).add(add, "hour").format("HH:mm");
}

export function addHour(date) {
  return moment(date).add(1, "hour").format("YYYY-MM-DD HH:mm");
}

export const locales = {
  en: "en",
  pt_BR: "pt-br",
  es: "es",
};

export function formatDateFullMonth(date) {
  moment.locale(store.getters.currentLanguageCode);
  let dateToFormat = moment(date);
  if (store.getters.currentLanguageCode !== "en") {
    return dateToFormat.format("DD [ de ] MMMM[ de ] YYYY");
  } else {
    return dateToFormat.format("MMM DD[, ] YYYY");
  }
}

export function formatDayFullMonth(date) {
  moment.locale(store.getters.currentLanguageCode);
  let dateToFormat = moment(date);
  if (store.getters.currentLanguageCode !== "en") {
    dateToFormat = dateToFormat.format("ddd, DD [ de ] MMM[. de ] YYYY");
  } else {
    dateToFormat = dateToFormat.format("ddd, MMM DD[, ] YYYY");
  }
  return dateToFormat.charAt(0).toUpperCase() + dateToFormat.slice(1);
}

export function formatHourPeriod(init_date, end_date) {
  let init = moment(init_date).format("HH:mm A");
  let end = moment(end_date).format("HH:mm A");
  return init + " - " + end;
}

export function formatStringHour(hour) {
  return hour.substring(0, hour.length - 3);
}

export function formatMoney(value) {
  value = parseFloat(value);

  if (isNaN(value)) {
    return value;
  }
  const formatter = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
}

export function formatDate(date) {
  if (!date) return null;

  return moment(date).format("DD/MM/YYYY");
}

export function formatDateForDatabase(date, initial_format = null) {
  if (!date) return null;

  if (initial_format) {
    return moment(date, initial_format).format("YYYY-MM-DD");
  } else {
    return moment(date).format("YYYY-MM-DD");
  }
}

export function formatHour(date, without_time_zone = false) {
  let hour = moment(date).locale("pt-br").format("LT");

  if (without_time_zone) {
    hour = moment(date).utc(false).locale("pt-br").format("LT");
  }

  return hour;
}

export function formatDateAndHour(date, without_time_zone = false) {
  let hour = moment(date).locale("pt-br").format("LT");
  let day = moment(date).locale("pt-br").format("L");

  if (without_time_zone == true) {
    hour = moment(date).utc(false).locale("pt-br").format("LT");
    day = moment(date).utc(false).locale("pt-br").format("L");
  }

  if (hour.slice(0, 2) == "00" || hour.slice(0, 2) == "01") {
    return `${day} à ${hour}`;
  }
  return `${day} às ${hour}`;
}

export function checkExpiredDate(date) {
  let today = moment().locale("pt-br").format("YYYY-MM-DD");
  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck < today;
}

export function checkIfIsGoingToExpire(date) {
  let today = moment().locale("pt-br");
  let dateToCheck = moment(date);
  return dateToCheck.isSameOrBefore(today) ? true : false;
}

export function formatPeriod(initDate, endDate) {
  initDate = moment(initDate).format("MM/DD/YYYY");
  const initDateFormated = moment(initDate).locale("pt-br").format("ll");

  if (endDate) {
    endDate = moment(endDate).format("MM/DD/YYYY");
    const endDateFormated = moment(endDate).locale("pt-br").format("ll");
    if (initDateFormated === endDateFormated) {
      return initDateFormated;
    }
    return `${initDateFormated} - ${endDateFormated}`;
  }
  return initDateFormated;
}

export function moveScreenToUp() {
  window.scrollTo(0, 0);
}

export function decimalCount(num) {
  const numStr = num.toString();
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  return 0;
}

export function redirectToErrorPage() {
  window.location.href = "/error";
}

export function dotsToComma(num, returnValue = "") {
  return num ? num.toString().replace(".", ",") : returnValue;
}

export const rulesBuilder = (component) => ({
  required: (v) => (!!v && v !== "") || component.$ml.get("required_field"),
  validateEmail: (v) =>
    /.+@.+\..+/.test(v) || component.$ml.get("invalid_email"),
});

export const passWordRules = {
  has_lower: false,
  has_number: false,
  has_symbol: false,
  has_upper: false,
  min_len: 0,
};

export const paging = {
  All: [25, 50, 100, 200],
  perPage: 50,
};

export const tableHeight = "calc(80vh - 160px)";
export function formatDateUTC(date) {
  if (!date) return null;

  return moment.utc(date).format("DD/MM/YYYY");
}

export function subtractDays(days) {
  return moment().subtract(days, "days").format("YYYY-MM-DD");
}

export function subtractMonths(days) {
  return moment().subtract(days, "months").format("YYYY-MM-DD");
}

export function scheduledVacation(user) {
  return moment(new Date()).isBefore(new Date(user.start_vacation_date));
}

export function formatDateDefault(date) {
  if (!date) return null;

  return moment(date).utc().format("YYYY-MM-DD");
}

export function removeMask(name, param) {
  const camps = {
    phone: param.replace(/[^0-9]/g, ""),
  };
  return camps[name];
}

export function removeCompanyInfoMask(name = "", param = "") {
  if (!name || !param) {
    return "";
  }

  const camps = {
    cnpj: param.replace(/[^0-9]+/g, ""),
    address_postal_code: param.replace(/[^0-9]+/g, ""),
    phone: param.replace(/[^0-9]/g, ""),
  };
  return camps[name];
}

export function checkExpiresToday(date) {
  let today = moment().locale("pt-br").format("YYYY-MM-DD");
  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck == today;
}

export function redirectToTheCommercialManagerGoogleCalendar() {
  window.open(calendarSchedule, "_blank");
}

export function openInNewTab(link) {
  window.open(link, "_blank");
}

export function initFreshChat() {
  let { name, email, domain } = store.getters.companyFreshChatConfig;

  window.fcWidget.init({
    token: "95dd279d-3dcf-470e-87c2-5807bdc16c9a",
    host: "https://wchat.freshchat.com",
    open: true,
    externalId: domain,
    firstName: name,
    email: email,
  });

  window.fcWidget.on("widget:closed", function () {
    window.fcWidget.destroy();
  });
}

export function parseToken(token) {
  if (!token) return;

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(window.atob(base64));
  return payload;
}

export function isTokenExpired(token) {
  if (!token) return;

  const tokenData = parseToken(token);

  if (!token || !tokenData) return true;

  const currentTimestamp = Math.floor(Date.now() / 1000);
  return tokenData.exp < currentTimestamp;
}

export function translateGroupDescription(json, ml) {
  let parsedJson = {};

  try {
    parsedJson = JSON.parse(json);
  } catch {
    return false;
  }

  let auto_logout = parsedJson.auto_logout
    ? ml.get("active_log")
    : ml.get("disabled_log");

  let advanced_auto_logout = parsedJson.advanced_auto_logout
    ? ml.get("active_log")
    : ml.get("disabled_log");

  let pontomais_login_rule = parsedJson.pontomais_login_rule
    ? ml.get("active_log")
    : ml.get("disabled_log");

  let pontomais_auto_logout_rules_0 = parsedJson.pontomais_auto_logout_rules_0
    ? ml.get("active_log")
    : ml.get("disabled_log");

  let pontomais_auto_logout_rules_1 = parsedJson.pontomais_auto_logout_rules_1
    ? ml.get("active_log")
    : ml.get("disabled_log");

  let description = `${ml.get("auto_logout")} : ${auto_logout}, ${ml.get(
    "advanced_logout_rule"
  )}: ${advanced_auto_logout}, ${ml.get(
    "pontomais_login_message"
  )}: ${pontomais_login_rule}, ${ml.get("force_logout_to_all_users")} ${ml.get(
    "pontomais_end_auto_logout_label"
  )}: ${pontomais_auto_logout_rules_0}, ${ml.get(
    "force_logout_to_all_users"
  )} ${ml.get(
    "pontomais_break_auto_logout_label"
  )}: ${pontomais_auto_logout_rules_1}`;

  return description;
}

export async function sendScheduleEmail() {
  let url = process.env.VUE_APP_API_BASE_URL + "/companies/schedule-interest";
  const token = store.getters.token;
  const payload = {
    financial_email: store.getters.companyInSuite.financial_email,
    users_number: store.getters.companyInSuite.users_number,
    verified_phone: store.getters.companyInSuite.verified_phone,
  };
  await axios.post(url, payload, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function removeAccents(str = "") {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function addDays(days) {
  return moment().add(days, "days").format("YYYY-MM-DD");
}

export function createDatetimeWithDateAndHour(date, hour) {
  return moment(`${date} ${hour}`).toISOString();
}

export const usersTableHeader = [
  {
    text: "name",
    align: "start",
    sortable: true,
    value: "name",
    width: 180,
    fixed: true,
    show: true,
  },
  {
    text: "email",
    value: "email",
    show: true,
  },
  { text: "status", value: "is_active", width: 160, show: true },
  {
    text: "updated_password_at",
    value: "updated_password_at",
    width: 180,
    show: true,
  },
  { text: "last_login", value: "last_login", width: 160, show: true },
  { text: "groups", value: "groups", show: true },
  { text: "function", value: "is_admin", width: 120, show: true },
  { text: "", value: "more", sortable: false, width: 30, show: true },
];

export function renewFeedbackOnSecondTime(feed) {
  if (!feed) return false;
  else if (feed == "ask_feedback") return true;
  return checkExpiredDate(feed);
}

export function setNotShowFeedbackToday(feedback) {
  let userFeedbacks = JSON.parse(localStorage.getItem("userFeedbacks")) || {};
  userFeedbacks[feedback] = todayUtc;
  localStorage.setItem("userFeedbacks", JSON.stringify(userFeedbacks));
}

export function checkShowFeedback(feedback) {
  let userFeedbacks = JSON.parse(localStorage.getItem("userFeedbacks"));
  if (!userFeedbacks || !userFeedbacks[feedback]) return true;
  return checkExpiredDate(userFeedbacks[feedback]);
}
